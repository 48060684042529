import MainIcon from './MainIcon.svelte';
const VI_ROOT_ID = 'voting-ideas-floating-cta'

let root = document.getElementById(VI_ROOT_ID);
if (!root) {
	root = document.createElement("div")
	root.id = VI_ROOT_ID
	document.body.appendChild(root)
}

root.innerHTML = '';
const app = new MainIcon({
	target: root
});

export default app;