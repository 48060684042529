<script>
	import Icon from './icons/lightbulb.svelte';
	import Loading from './icons/loading.svelte';
	import MainContainer from './MainContainer.svelte';

	let show = false;
	const open = () => (show = !show)

	window.addEventListener('voting-ideas-open', () => {
		console.log('open voting ideas');
		open()
	});
	
	let lightBulbColor = 'transparent';

	$: handleHover = () => (lightBulbColor = '#ffe1b1')
	$: handleOut = () => (lightBulbColor = 'transparent')

	const styleLoad = new Promise((resolve) => {
		var link = document.createElement("link");
		link.rel = 'stylesheet';  
		link.type = 'text/css';
		link.onload = resolve;
		// link.href = 'http://localhost:5000/build/bundle.css';
		link.href = 'https://agent.votingideas.com/bundle.css';
		document.getElementsByTagName('head')[0].appendChild(link);
	});
</script>

<div id="voting-ideas-button-container">
	{#await styleLoad}
		<button>
			<Loading />
		</button>
	{:then}
		<button id="voting-ideas-button" on:mouseover={handleHover} on:mouseout={handleOut} on:click={open}>
			<Icon innerColor="{lightBulbColor}" />
		</button>
		<MainContainer show={show} />
	{/await}
</div>

<style>
	#voting-ideas-button-container {
		position: fixed;
		bottom: 20px;
		right: 20px;
		z-index: 999999;
	}
	#voting-ideas-button {
    display: flex;
    justify-content: center;
    align-items: center;
		width: 60px;
		height: 60px;
		background-color: #ffae00;
		border-radius: 50%;
		border: none;
		transition: all .2s;
		cursor: pointer;
		outline: none;
	}

	#voting-ideas-button:hover {
		background-color: #ffc039;
	}

	#voting-ideas-button:active {
		transform: scale3d(1.1,1.1,1.1);
	}
</style>