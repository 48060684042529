<script lang="ts">
	import { onMount } from "svelte";

	export let show = false;

	// const clientUrl = "http://localhost:3010";
	// const apiUrl = "http://localhost:3000";
	
	const clientUrl = 'https://client.votingideas.com'
	const apiUrl = "https://api.votingideas.com";

	let appId;
	const findAppId = fetch(`${apiUrl}/applications-domain?domain=${window.location.host}`)
			.then(async res => await res.json())
			.then(res => {
				return appId = res.id;
			})
			.catch(err => {
				return appId = window.votingIdeas ? window.votingIdeas.id : '020b9511-87b0-481f-a01f-5bb467258c9e';
			})

</script>

<div class={show ? "active" : ""} id="voting-ideas-container">
	{#await findAppId}
		<span>carregando</span>
	{:then}
		<iframe
			id="voting-ideas-iframe"
			title="Voting Ideas"
			src={`${clientUrl}?appId=${appId}`}
			style="height: 100%; width: 100%; border: none;"
		/>
	{/await}
</div>

<style>
	#voting-ideas-container {
		position: fixed;
		bottom: 100px;
		right: 20px;
		width: 376px;
		background: #fff;
		border: black;
		transition: transform 0.4s 0s, opacity 0.2s, height 0.01s 0.4s;
		transform: translateY(10%);
		opacity: 0;
		border-radius: 8px;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		overflow: hidden;
		height: 0;
		min-height: 0px;
		max-height: 704px;
	}

	#voting-ideas-container.active {
		transform: translateY(0);
		opacity: 1;
		transition: transform 0.5s 0s, opacity 0.3s 0.2s, height 0.01s 0s;
		height: 70vh;
		min-height: 250px;
		max-height: 704px;
	}

	@media (max-width: 420px) {
		#voting-ideas-container {
			width: auto;
			left: 4px;
			right: 4px;
		}
	}
</style>
