<div class="lds-dual-ring"></div>
<style>
/* .lds-ellipsis {
  display: inline-block;
  position: absolute;
    top: -8px;
    left: -8px;
    transform: scale(.7);
  width: 80px;
  height: 80px;
} */
.lds-dual-ring {
  display: inline-block;
  position: absolute;
    top: -12px;
    left: -12px;
    transform: scale(.5);
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid rgb(255, 255, 255);
  border-color: rgb(255, 255, 255) transparent rgb(255, 255, 255) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>